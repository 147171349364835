import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ Disaster Recovery Plan policy.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Since disasters happen so rarely, management often ignores the disaster
recovery planning process. It is important to realize that having a
contingency plan in the event of a disaster gives Data Migrators a
competitive advantage. This policy requires management to financially
support and diligently attend to disaster contingency planning efforts.
Disasters are not limited to adverse weather conditions. Any event that
could likely cause an extended delay of service should be considered.
The Disaster Recovery Plan is often part of the Business Continuity
Plan.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`This policy defines the requirement for a baseline disaster recovery
plan to be developed and implemented by Data Migrators that will
describe the process to recover IT Systems, Applications and Data from
any type of disaster that causes a major outage.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy is directed to the IT Management Staff who is accountable to
ensure the plan is developed, tested and kept up-to-date. This policy is
solely to state the requirement to have a disaster recovery plan, it
does not provide requirement around what goes into the plan or
sub-plans.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`4.1 Contingency Plans`}</p>
    <p>{`The following contingency plans must be created:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Computer Emergency Response Plan: Who is to be contacted, when, and
how? What immediate actions must be taken in the event of certain
occurrences?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Succession Plan: Describe the flow of responsibility when normal
staff is unavailable to perform their duties.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Data Study: Detail the data stored on the systems, its criticality,
and its confidentiality.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Criticality of Service List: List all the services provided and
their order of importance.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`It also explains the order of recovery in both short-term and
long-term timeframes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Data Backup and Restoration Plan: Detail which data is backed up,
the media to which it is saved, where that media is stored, and how
often the backup is done. It should also describe how that data
could be recovered.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Equipment Replacement Plan: Describe what equipment is required to
begin to provide services, list the order in which it is necessary,
and note where to purchase the equipment.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Mass Media Management: Who is in charge of giving information to the
mass media?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Also provide some guidelines on what data is appropriate to be
provided.`}</p>
      </li>
    </ul>
    <p>{`After creating the plans, it is important to practice them to the
extent possible. Management should set aside time to test
implementation of the disaster recovery plan. Table top exercises
should be conducted annually. During these tests, issues that may
cause the plan to fail can be discovered and corrected in an
environment that has few consequences.`}</p>
    <p>{`The plan, at a minimum, should be reviewed an updated on an annual
basis.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <h3>{`Compliance Measurement`}</h3>
    <p>{`The Infosec team will verify compliance to this policy through various
methods, including but not limited to, periodic walk-thrus, video
monitoring, business tool reports, internal and external audits, and
feedback to the policy owner.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`Any exception to the policy must be approved by the Infosec Team in
advance.`}</p>
    <h2>{`Non-Compliance`}</h2>
    <p>{`An employee found to have violated this policy may be subject to
disciplinary action, up to and including termination of employment.`}</p>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`The following definition and terms can be found in the `}<a parentName="p" {...{
        "href": "https://www.sans.org/security-resources/glossary-of-terms/"
      }}>{`SANS Glossary`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`Disaster`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      